import React from 'react';
import Styles from './LinksPage.module.scss'
import { useVm } from './LinksPage.vm';
import { isEmpty } from 'lodash';
import { Button, Input, Space } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';

export const LinksPage: React.FC = () => {
  const {
    links,
    link,
    setLink,
    addLink,
    isUploading
  } = useVm()

  return <div className={Styles.page}>
    <h3 >{'連結分享'}</h3>
    <Space.Compact className={Styles.addLink}>
      <Input
        placeholder={'https://your-link/paste/here'}
        value={link}
        onChange={e => setLink(e.currentTarget.value)} />
      <Button
        type="primary"
        icon={<UploadOutlined />}
        disabled={isEmpty(link)}
        loading={isUploading}
        onClick={addLink} />
    </Space.Compact>
    <h3>{'已連結的分享'}</h3>
    {!links && <LoadingOutlined className={Styles.loading} spin />}
    {links && isEmpty(links) && <label className={Styles.empty}>{'尚未有任何連結'}</label>}
    {links && !isEmpty(links) &&
      links.map((v, i) => (
        <div key={i} className={Styles.linkItem}>
          <label>{v.type}</label>
          <a
            href={v.url}
            target="_blank"
            rel="noreferrer">{v.url}</a>
          {v.createdAt && <label>{dayjs(v.createdAt).fromNow()}</label>}
        </div>
      ))
    }
  </div >
}