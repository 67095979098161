import { message } from "antd"
import { useState, useCallback, useEffect } from "react"
import { Link } from "src/models"
import {
    getLinks as getLinksByApi,
    addLink as addLinkByApi,
} from "src/api"


export const useVm = () => {
    const [link, setLink] = useState<string>('')
    const [links, setLinks] = useState<Link[] | undefined>()
    const [isUploading, setIsUploading] = useState(false)

    const addLink = useCallback(async () => {
        try {
            setIsUploading(true)
            await addLinkByApi({ type: 'livestream', url: link })
            setLink('')
            message.success('成功')
        } catch (error: any) {
            message.error('失敗: ' + error.message)
        }
        setIsUploading(false)
        load()
    }, [link])

    const load = async () => {
        try {
            const result = await getLinksByApi()
            setLinks(result)
        } catch (error: any) {
            message.error('載入失敗: ' + error.message)
        }
    }

    useEffect(() => {
        load()
    }, [])

    return {
        links,
        link,
        setLink,
        addLink,
        isUploading
    }
}