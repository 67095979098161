export const routes = {
    events: 'events',
    members: 'members',
    settings: 'settings',
    reports: 'reports',
    sharesWK: 'shares-wk',
    sharesMC: 'shares-mc',
    news: 'news',
    signIn: 'sign-in',
    posts: 'posts',
    links: 'links',
}
export type Routes = keyof typeof routes

export const getRoutes = ({ role, team }: { role: string, team: string }): Routes[] => {
    const arr: Routes[] = []
    switch (team) {
        case '管理員':
            arr.push('sharesWK', 'reports', 'news')
            break
        case '分享':
            arr.push('sharesMC')
            break
        case '直播組':
            arr.push('links')
            break
        default:
            arr.push('events', 'posts', 'settings')
            break
    }
    return arr
}